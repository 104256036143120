import { useEffect, useState } from 'react';
import React, { Suspense, lazy } from 'react';
import { useTranslation } from "react-i18next";

import Header from '../../components/MarginalsV2/Header';
import CommonContent from './CommonContent';
import Footer from '../../components/MarginalsV2/Footer';
// import BuyBtnPopup from '../../components/forms/Portal/BuyBtnPopup';
import SignInModal from '../../components/forms/Modals/SignInModal';
import useStateWithLocalStorage from '../../helpers/useStateWithLocalStorage';
import Products from '../../components/ProductsV2';
import MainContent from './MainContent/he';

export default function Homepage() {
  const { i18n, t } = useTranslation();
  // const [signinBuyBtn, setSigninBuyBtn] = useState(false);
  const [mainSignin, setMainSignin] = useState(false);
  const [documentId, setDocumentId] = useStateWithLocalStorage('documentId');
  useEffect(() => {
    document.title = t("idp")
    // if (window.gtag) {
    //   window.gtag('set', 'user_properties', { 'homepage_version': 'new' })
    // }
    setDocumentId("");
  }, [])

  const showBuyBtnHandler = () => {
    // setSigninBuyBtn(true);
  };

  const hideBuyBtnHandler = () => {
    // setSigninBuyBtn(false);
  };

  const showSigninHandler = () => {
    setMainSignin(true);
  };
  const hideSigninHandler = () => {
    setMainSignin(false);
  };

  const MainContent = lazy(() =>
    import(`./MainContent/${i18n.language}.jsx`).catch(() =>
      import('./MainContent/en.jsx') // fallback to English if language component not found
    )
  );

  return (
    <div className="home">
      {mainSignin && <SignInModal onHide={hideSigninHandler} />}
      <Header onShowSignin={showSigninHandler} />
      <CommonContent />
      <Products onShowForm={showBuyBtnHandler} />
      <Suspense fallback={<div>t("loading")...</div>}>
        <MainContent />
      </Suspense>
      <Products onShowForm={showBuyBtnHandler} />
      <Footer showLegal={true} />
    </div>
  );
}
