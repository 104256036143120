import React from 'react';
import "./style.css";

const MainContent = () => (
  <div className="main-content">
      <h2 className="section_title">שאלות נפוצות</h2>
      <section>
      <h3 className="section_title">מדוע יש צורך ברישיון נהיגה בינלאומי (IDP)?</h3>
      <p>
        רישיון נהיגה בינלאומי מאפשר לכם לנהוג בצורה חוקית במדינות רבות. הוא משמש כתרגום רשמי של רישיון הנהיגה שלכם ומאפשר לחברות השכרה ורשויות במדינות זרות לאמת את כשירותכם לנהיגה.
      </p>
    </section>

    <section>
      <h3 className="section_title">כיצד משיגים רישיון נהיגה בינלאומי?</h3>
      <p>
        תוכלו לקבל רישיון נהיגה בינלאומי באתר שלנו. עליכם להזדהות באמצעות צילום של רישיון הנהיגה הישראלי שלכם ולבצע תשלום בכרטיס אשראי. התהליך קצר ופשוט ותוך פחות מחמש דקות תוכלו לסיים אותו.
      </p>
    </section>

    <section>
      <h3 className="section_title">סוגי רישיונות בינלאומיים הזמינים לרכישה</h3>
      <p>
        רישיון הנהיגה הבינלאומי שלנו הוא דיגיטלי וניתן להזמנה אוןליין. הוא תקף לשנה אחת בלבד, עולה 29$ ומוכן בתוך 24 שעות מרגע ההזמנה. אם אתם ממהרים, או שאתם כבר בדלפק חברת ההשכרה וחייבים רישיון בינלאומי באופן דחוף, תוכלו להזמין רישיון דיגיטלי מיידי במחיר של 69$.
        רישיון זה מוכן תוך 60 דקות מרגע ההזמנה.
      </p>
    </section>

    <section>
      <h3 className="section_title">כיצד מבררים אם רישיון דיגיטלי תקף או לא?</h3>
      <p>
        הכי נכון לברר ישירות מול חברת ההשכרה. חברות השכרה שונות יכולות לדרוש רישיון נהיגה בינלאומי מודפס ולא יקבלו את הרישיון הדיגיטלי. אל תרכשו רישיון דיגיטלי אם חברת ההשכרה לא תקבל אותו.
      </p>
    </section>
  </div>
);

export default MainContent;